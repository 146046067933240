import styled from 'styled-components';
import { SecondaryButton } from '@/components/core/Button';
import errorSVG from '@/public/assets/generic-error.svg';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
`;

export const Title = styled.h3`
  padding-bottom: 24px;
  font-family: ${(props) => props.theme.fonts.title};
  font-size: 45px;
  line-height: 56px;
  font-weight: 700;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 30px;
    line-height: 40px;
    padding-bottom: 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 25px;
    line-height: 32px;
  }
`;

export const Subtitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  padding-bottom: 24px;
`;

export const MainButton = styled(SecondaryButton)`
  padding-left: 32px;
  padding-right: 32px;
`;

export const ImageWrapper = styled(errorSVG)`
  margin-bottom: 24px;
  margin-top: -24px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 101px;
    height: 101px;
  }
`;
