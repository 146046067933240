import Head from 'next/head';
import Error404 from '@/components/404';
import { DefaultSEO } from '@/config/seo-meta.js';

export default function Page404() {
  const { defaultTitle } = DefaultSEO;
  return (
    <>
      <Head>
        <title>{`Not Found | ${defaultTitle}`}</title>
      </Head>

      <Error404
        title="Page not found"
        subtitle={`Sorry, but the page you were looking for couldn't be found.`}
        buttonLabel="Return Home"
      />
    </>
  );
}
