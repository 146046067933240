import Link from 'next/link';
import { Container } from '@/components/core';
import { Wrapper, ImageWrapper, Title, Subtitle, MainButton } from './styles';

interface ErrorType {
  title: string;
  subtitle: string;
  buttonLabel?: string;
}

export default function Error404({ title, subtitle, buttonLabel }: ErrorType) {
  return (
    <Wrapper>
      <Container>
        <ImageWrapper />

        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>

        {buttonLabel && (
          <Link href="/">
            <MainButton forwardedAs="span">{buttonLabel}</MainButton>
          </Link>
        )}
      </Container>
    </Wrapper>
  );
}
